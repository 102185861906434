<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Filtros </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-select
                  :items="cities"
                  v-model="filters.cityId"
                  label="Cidade"
                ></v-select>
              </v-col>
            </v-row>

            <div class="text-right">
              <v-btn @click="clearFilters()" text class="mr-2"
                >Limpar filtros</v-btn
              >
              <v-btn color="primary" @click="loadNews()">Filtrar</v-btn>
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mt-4">
      <v-card-title>Notícias</v-card-title>

      <v-data-table
        :headers="headers"
        :items="news"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              small
              color="success"
              @click="$router.push('/noticias/cadastrar')"
              >Cadastrar +</v-btn
            >
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="mr-2"
            title="Editar perfil"
            @click="editCategory(item.id)"
            small
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            title="Deletar perfil"
            @click="deleteNews(item.id)"
            small
            icon
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Título", value: "title" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    news: [],
    cities: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async loadNews() {
      try {
        this.loading = true;

        let url = `/news`;

        const response = await this.$axios.get(url, {
          params: this.filters,
        });

        this.setNews(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setNews(data) {
      const cpData = [...data];
      this.news = cpData;
    },
    async loadCities() {
      try {
        this.loadingCities = true;

        let url = `/cities`;

        const response = await this.$axios.get(url);

        this.setCities(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingCities = false;
      }
    },
    setCities(data) {
      const parsedData = [...data];

      this.cities = parsedData.map((c) => ({
        text: `${c.name} - ${c.uf}`,
        value: c.id,
      }));
    },
    async deleteNews(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar a notícia com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/news/${id}`;

        await this.$axios.delete(url);

        this.deleteNewsFromTable(id);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteNewsFromTable(id) {
      const index = this.news.findIndex((p) => p.id === id);

      this.news.splice(index, 1);
    },
    editCategory(id) {
      this.$router.push(`/noticias/${id}`);
    },
    clearFilters() {
      this.filters = { currentPage: 1 };

      this.loadNews();
    },
  },
  computed: {},
  created() {
    this.loadNews();
    this.loadCities();
  },
};
</script>

<style></style>
